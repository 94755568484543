const SvgComponent = () => (
	<svg width={187} height={165} viewBox='0 0 187 165' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<g id='Group 1'>
			<path id='Vector 368' d='M33.3545 1.71509C33.3545 29.2956 28.8695 56.8488 30.9912 84.4305' stroke='white' strokeWidth={3} strokeLinecap='round' />
			<path id='Vector 369' d='M1.61877 3.74078C2.6238 4.2768 4.07447 4.06092 5.14496 4.0784C15.8464 4.25311 26.5274 5.21867 37.1995 5.95403C57.5109 7.35358 77.8126 8.46738 98.1763 8.46738' stroke='white' strokeWidth={3} strokeLinecap='round' />
			<path id='Vector 370' d='M92.4369 42.2288C83.0124 42.2288 71.7626 42.4006 65.1652 50.294C60.1134 56.338 56.9039 66.7158 59.4633 74.4521C61.7062 81.2318 69.9187 83.8957 76.2689 84.5806C83.1778 85.3256 90.7401 84.3525 94.6126 77.8845C96.67 74.4483 97.5898 70.4012 98.4389 66.537C99.3281 62.4901 100.579 57.714 99.9769 53.5201C98.7998 45.3161 86.7174 42.3469 80.1515 40.9158C75.3052 39.8596 70.3738 39.5279 65.4278 39.5279' stroke='white' strokeWidth={3} strokeLinecap='round' />
			<path id='Vector 371' d='M141.391 55.0581C139.684 55.1435 138.047 55.8947 136.439 56.4086C132.718 57.5974 128.632 58.6376 125.41 60.9476C121.913 63.456 119.524 71.058 122.597 74.7898C125.498 78.3121 130.318 81.7557 134.976 82.0297C138.966 82.2644 142.429 80.9333 146.117 79.7039' stroke='white' strokeWidth={3} strokeLinecap='round' />
			<path id='Vector 372' d='M142.741 14.8821C141.934 18.716 142.328 23.0325 142.591 26.9049C143.049 33.6464 143.724 40.2638 143.754 47.0304C143.786 54.1342 145.105 61.1136 145.105 68.2251C145.105 72.8413 144.589 77.7524 145.18 82.3298C145.432 84.2826 146.793 86.2422 146.793 88.1443' stroke='white' strokeWidth={3} strokeLinecap='round' />
			<path id='Vector 373' d='M178.866 64.8489C177.492 60.2691 170.196 70.091 169.713 70.6446C166.791 73.9957 156.831 87.3646 165.211 90.3575C167.382 91.1327 170.8 90.0773 172.864 89.5885C175.629 88.9336 178.285 87.972 180.892 86.8501C181.987 86.3785 183.463 85.9106 184.212 84.8994C185.509 83.1478 185.055 79.6969 184.568 77.7908C183.198 72.4269 179.643 67.9887 175.828 64.1737' stroke='white' strokeWidth={3} strokeLinecap='round' />
			<path id='Vector 374' d='M89.4314 119.574C85.4088 119.191 83.7843 118.3 80.7498 121.801C79.5606 123.173 78.6219 125.201 78.903 127.075C79.2702 129.522 82.7469 130.158 84.7098 130.388C90.1157 131.022 92.2175 127.773 91.7922 122.563C91.4888 118.846 87.3834 120.485 84.9764 121.287' stroke='white' strokeWidth={3} strokeLinecap='round' />
			<path id='Vector 375' d='M87.3752 146.304C83.3286 146.378 80.0616 147.41 78.5413 151.445C77.342 154.627 80.4582 157.778 83.4342 158.432C86.5731 159.121 90.0787 155.027 90.0787 151.997C90.0787 149.728 87.3218 149.461 85.6617 149.046' stroke='white' strokeWidth={3} strokeLinecap='round' />
			<path id='Vector 376' d='M113.42 118.546C111.563 119.887 110.82 123.464 110.165 125.495C108.294 131.288 106.835 137.404 106.604 143.505C106.308 151.346 109.982 159.15 117.19 162.754' stroke='white' strokeWidth={3} strokeLinecap='round' />
		</g>
	</svg>
);
export default SvgComponent;
